import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link as GatsbyLink } from 'gatsby'
import { Title, Box } from "../Core";
import Logo from "../Logo";
import instagram from "../../assets/image/social-icons/instagram.png"
import fb from "../../assets/image/social-icons/facebook.png"
import twitter from "../../assets/image/social-icons/twitter.png"

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
`;

const Link = styled.a`
  color: white !important;
  &:hover {
    color: white !important;
  }
`;

// const UlStyled = styled.ul`
//   margin: 0;
//   padding: 0;
//   list-style: none;
//   li {
//     line-height: 2.25;
//     a {
//       color: ${({ theme, color }) => theme.colors[color]} !important;
//       &:hover {
//         text-decoration: none;
//         color: ${({ theme, color }) => theme.colors.secondary} !important;
//       }
//     }
//   }
// `;

const CopyRightArea = styled.div`
  paddingbottom: 20px;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
      }
    }
    img {
      width: 16px;
    }
  }
`;

// const LogoText = styled.span`
//   font-size: 1em;
//   text-align: center;
//   color: primary;
//   padding-left: 2px;
//   color: #ffffff;
// `;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"} css={`
              padding-bottom: 15px;
            `}>
        <Container>
          <Box
            css={`
              padding: 20px 0 20px;
            `}
          >
            <Row className="justify-content-center align-items">
              <Col lg="2" md="4">
                <GatsbyLink to="/">
                  <Logo white={isDark} />
                </GatsbyLink>
              </Col>
              <Col lg="10" md="8" className="mt-2 pl-md-5 mt-lg-0 d-flex align-items-center flex-row">
                <Row className="pl-3 pl-lg-0 w-100">
                  <Col xs="5" lg="3">
                    <TitleStyled
                      variant="card"
                      color={isDark ? "light" : "dark"}
                      marginBottom={"0px"}
                    >
                      <Link href="/privacy" target="_self">
                        Privacy Policy
                      </Link>
                    </TitleStyled>
                  </Col>
                  <Col xs="6" lg="3">
                    <TitleStyled
                      variant="card"
                      color={isDark ? "light" : "dark"}
                      marginBottom={"0px"}
                    >
                      <Link href="/terms" target="_self">
                        Terms
                      </Link>
                    </TitleStyled>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center pl-md-5">
              <Col sm="6" className="text-left text-lg-center mb-2 mb-sm-0">
                <p>Copyright &copy; 2021 RewardUp Inc. All rights reserved.</p>
              </Col>
              <Col sm="6" className="text-left text-lg-center d-flex justify-content-center align-items-center">
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://twitter.com/RewardUpInc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/rewardupinc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={instagram} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/rewardupinc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fb} alt="" />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
