import React from "react";
// import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/svg/logo.svg";
import imgL1LogoWhite from "../../assets/image/svg/logo-white.svg";

const Logo = ({ white, ...rest }) => {
    return (
        <img
            src={white ? imgL1LogoWhite : imgL1Logo}
            alt=""
            width="180px"
            className="d-block d-xl-block d-lg-block"
            {...rest}
        />
    );
};

export default Logo;
