export const menuItems = [
  {
    to: '/#rewards',
    label: 'Rewards',
  },
  {
    to: '/#feedback',
    label: 'Feedback',
  },
  {
    to: '/#promotions',
    label: 'Promotions',
  },
  {
    to: '/#referrals',
    label: 'Referrals',
  }
]
