import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "gatsby";

import GlobalContext from "../../context/GlobalContext";
import Offcanvas from "../Offcanvas";
import { Button } from "../Core";
import NestedMenu from "../NestedMenu";
import { device } from "../../utils";
import Logo from "../Logo";
import { menuItems } from "./menuItems";

const SiteHeader = styled.header`
    padding: 10px 0 10px 0;
    position: fixed !important;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 999;

    &.scrolling {
        transform: translateY(-100%);
        transition: 0.4s;
    }
    &.reveal-header {
        transform: translateY(0%);
        box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
        z-index: 9999;
        background: ${({ dark, theme }) =>
            dark ? theme.colors.dark : theme.colors.light};
    }
    @media ${device.lg} {
        position: fixed !important;
        transition: 0.4s;
        &.scrolling {
            transform: translateY(-100%);
            transition: 0.4s;
        }
        &.reveal-header {
            transform: translateY(0%);
            box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
            z-index: 9999;
        }
    }
`;

const ToggleButton = styled.button`
    color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.primary}!important;
    border-color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.primary}!important;
`;

const Menu = styled.ul`
    @media ${device.lg} {
        display: flex;
        justify-content: flex-end;
    }
    .dropdown-toggle {
        cursor: pointer;
    }
    > li {
        > .nav-link {
            @media ${device.lg} {
                color: ${({ dark, theme }) =>
                    dark ? theme.colors.light : theme.colors.dark}!important;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                padding-top: 18px !important;
                padding-bottom: 18px !important;
                padding-left: 18px !important;
                padding-right: 18px !important;
            }
        }
    }
    .nav-item.dropdown {
        @media ${device.lg} {
            position: relative;
            z-index: 99;
        }
        &:hover {
            > .menu-dropdown {
                @media ${device.lg} {
                    top: 90%;
                    opacity: 1;
                    pointer-events: visible;
                }
            }
        }
    }
`;

export const MenuDropdown = styled.ul`
    list-style: none;
    @media ${device.lg} {
        top: 110%;
        position: absolute;
        min-width: 227px;
        max-width: 227px;
        box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background-color: #ffffff;
        padding: 15px 0px;
        z-index: 99;
        opacity: 0;
        transition: opacity 0.4s, top 0.4s;
        pointer-events: none;
        left: -90%;
        border-radius: 0 0 10px 10px;
        border: 1px solid #eae9f2;
        background-color: #ffffff;
        display: block;
        border-top: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
    }
    > .drop-menu-item {
        color: ${({ theme }) => theme.colors.dark};
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;

        a {
            color: ${({ theme }) => theme.colors.dark};
            transition: all 0.3s ease-out;
            position: relative;
            display: flex;
            align-items: center;
            &.dropdown-toggle::after {
                display: inline-block;
                vertical-align: 0.255em;
                content: "";
                border-top: 0.325em solid;
                border-right: 0.325em solid transparent;
                border-bottom: 0;
                border-left: 0.325em solid transparent;
                position: relative;
                top: 1px;
                margin-left: auto;
                transform: rotate(-90deg);
                transition: 0.4s;
            }
        }

        &:hover {
            > a {
                color: ${({ theme }) => theme.colors.secondary};
                text-decoration: none;
                &::after {
                    transform: rotate(0deg);
                }
            }
        }
        &.dropdown {
            position: relative;

            &:hover {
                > .menu-dropdown {
                    @media ${device.lg} {
                        top: 10px;
                        opacity: 1;
                        pointer-events: visible;
                        transform: translateX(-100%);
                    }
                }
            }
            > .menu-dropdown {
                border-top-color: ${({ theme }) => theme.colors.primary};
                @media ${device.lg} {
                    top: 10px;
                    left: 0%;
                    opacity: 0;
                    transform: translateX(-110%);
                    transition: 0.4s;
                    pointer-events: none;
                }
                > .drop-menu-item {
                    @media ${device.lg} {
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }
            }
        }
    }
    &.dropdown-right {
        left: auto;
        right: -90%;
    }
`;

const Header = ({ isDark = false, hasBanner, location }) => {
    const gContext = useContext(GlobalContext);
    const [showScrolling, setShowScrolling] = useState(false);
    const [showReveal, setShowReveal] = useState(false);

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < 0) {
            setShowScrolling(true);
        } else {
            setShowScrolling(false);
        }
        if (currPos.y < -300) {
            setShowReveal(true);
        } else {
            setShowReveal(false);
        }
    });

    return (
        <>
            <SiteHeader
                className={`sticky-header ${showScrolling ? "scrolling" : ""} ${
                    showReveal ? "reveal-header" : ""
                }`}
                dark={isDark ? 1 : 0}
            >
                <Container fluid>
                    <nav className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-light">
                        {/* <!-- Brand Logo--> */}
                        <div className="brand-logo">
                            <Link to="/">
                                <Logo
                                    white={
                                        hasBanner && !showReveal ? true : isDark
                                    }
                                />
                            </Link>
                        </div>
                        <div className="collapse navbar-collapse">
                            <div className="navbar-nav ml-lg-auto mr-3">
                                <Menu
                                    className="navbar-nav d-none d-lg-flex"
                                    dark={
                                        hasBanner && !showReveal ? true : isDark
                                    }
                                >
                                    {menuItems.map((x) => (
                                        <li className="nav-item" key={x.label}>
                                            <Link
                                                className="nav-link"
                                                to={x.to}
                                                role="button"
                                                aria-expanded="false"
                                            >
                                                {x.label}
                                            </Link>
                                        </li>
                                    ))}
                                </Menu>
                            </div>
                        </div>
                        <div className="header-btns ml-auto ml-lg-0 d-none d-md-block">
                            <Button
                                size="sm"
                                css={`
                                    font-size: 16px !important;
                                    min-width: 141px !important;
                                    height: 45px !important;
                                    letter-spacing: 0.1px !important;
                                `}
                                onClick={() => window.location.href= process.env.GATSBY_STORE_URL}
                            >
                                Log In
                            </Button>
                        </div>
                        <ToggleButton
                            className={`navbar-toggler btn-close-off-canvas ml-3 ${
                                gContext.visibleOffCanvas ? "collapsed" : ""
                            }`}
                            type="button"
                            data-toggle="collapse"
                            data-target="#mobile-menu"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={gContext.toggleOffCanvas}
                            dark={isDark ? 1 : 0}
                        >
                            {/* <i className="icon icon-simple-remove icon-close"></i> */}
                            <i className="icon icon-menu-34 icon-burger d-block"></i>
                        </ToggleButton>
                    </nav>
                </Container>
            </SiteHeader>
            <Offcanvas
                show={gContext.visibleOffCanvas}
                onHideOffcanvas={gContext.toggleOffCanvas}
            >
                <NestedMenu menuItems={menuItems} />
            </Offcanvas>
        </>
    );
};
export default Header;
