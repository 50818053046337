import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";
import { device } from "../../utils";

const Paragraph = styled.p`
  margin-bottom: 0;
  font-weight: 300;
  letter-spacing: 0.1px;
  font-size: 17px;
  line-height: 22px;
  ${color};
  ${space};
  ${typography};
  ${shadow};

  @media ${device.md} {
    font-size: 17px;
    line-height: 24px;
  }

  @media ${device.lg} {
    font-size: 17px;
    line-height: 24px;
  }

  @media ${device.xl} {
    font-size: 20px;
    line-height: 30px;
  }
`;

const ParagraphSmall = styled(Paragraph)`
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
  @media ${device.md} {
    font-size: 16px;
    line-height: 18px;
  }

  @media ${device.lg} {
    font-size: 16px;
    line-height: 20px;
  }

  @media ${device.xl} {
    font-size:18px;
    line-height: 30px;
  }
`;

const Text = ({ variant, ...props }) => {
  let TextRender;

  switch (variant) {
    case "small":
      TextRender = ParagraphSmall;
      break;
    default:
      TextRender = Paragraph;
  }

  return <TextRender color="text" {...props} />;
};

export default Text;
